import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import axios from 'axios'
Vue.config.productionTip = false
// axios.defaults.baseURL = 'http://kingforn.mynatapp.cc'
// Vue.prototype.$http = axios
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
